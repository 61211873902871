<template lang="html">

  <section class="video-type-form">
    <h1>video-type-form Component</h1>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'VideoTypeForm',
      props: {
          video: {
              type: Object,
              required: true
          },
          playlist: {
              type: Object,
              required: true
          }
      },
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .video-type-form {

  }
</style>
