<template lang="html">
    <section class="uptobox-form">
        <div class="parent">
            <div class="site-list">
                <v-btn v-for="(item, index) in sites" :key="index" v-on:click="open(item)" color="#805acb" class="child">
                    <v-img :src="item.icon" class="icon"></v-img>
                    {{ item.name }}
                </v-btn>
            </div>
            <v-divider inset vertical></v-divider>
            <div>
                <div id="links">
                    <div v-for="(link, index) in links" :key="index">
                        <span>{{ link.title }} : {{ link.uptoboxLink }}</span>
                        <v-btn @click="deleteLink(link)" icon color="#f44336"><v-icon>mdi-delete</v-icon></v-btn>
                    </div>
                </div>
                <div style="margin-left: 100px; display: grid; grid-template-columns: 200px 200px 50px; align-items: end; gap: 10px;">
                    <v-text-field v-model="videoTitle" append-icon="mdi-movie-open" label="Video title" single-line hide-details></v-text-field>
                    <v-text-field v-model="videoLink" append-icon="mdi-link-variant" label="Uptobox link" single-line hide-details></v-text-field>
                    <v-btn @click="addLink()" color="secondary"><v-icon>mdi-check</v-icon></v-btn>
                </div>
                <v-btn @click="setUptoboxLink" depressed color="primary" style="margin-top: 20px;">Validate</v-btn>
            </div>
        </div>

        <v-dialog v-if="isChosenSiteValid" v-model="showIframe" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="#805acb">
                    <v-btn icon dark @click="showIframe = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Settings</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="showIframe = false">Help</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <iframe :src="chosenUrl" id="iframe" class="iframe" ref="frame" @load="load"></iframe>
            </v-card>
        </v-dialog>
    </section>
</template>

<script lang="js">

export default {
    name: 'UptoboxForm',
    props: {
        playlistProp: {
            type: Object,
            required: true
        },
    },
    mounted() {

    },
    computed: {
        isChosenSiteValid() {
            return this.chosenSite !== null && this.chosenSite.url !== null && this.chosenSite.url !== '';
        },
        chosenUrl() {
            return `//${location.hostname}/frontdesk-api/crosssite?from=${encodeURI(this.chosenSite.url)}`;
        }
    },
    data: () => ({
        showIframe: false,
        sites: [
            {
                name: 'Zone Téléchargement',
                icon: 'https://www.zone-telechargement.homes/templates/zone/images/favicon.ico',
                buildUrl: ({ title, type }) => {
                    const url = 'https://www.zone-telechargement.homes/';
                    const titleEncoded = encodeURI(title);
                    const typeParsed = type === "movie" ? "films" : "series";
                    return `${url}?search=${titleEncoded}&p=${typeParsed}`;
                }
            },
            {
                name: 'Annuaire Telechargement',
                icon: 'https://www.annuaire-telechargement.homes/favicon.ico',
                buildUrl: ({ title, type }) => {
                    const url = 'https://www.zone-telechargement.homes/';
                    const titleEncoded = encodeURI(title);
                    const typeParsed = type === "movie" ? "films" : "series";
                    return `${url}?search=${titleEncoded}&p=${typeParsed}`;
                }
            },
            {
                name: 'Zone Annuaire',
                icon: 'https://www.zone-annuaire.homes/favicon.ico',
                buildUrl: ({ title, type }) => {
                    const url = 'https://www.zone-annuaire.homes/';
                    const titleEncoded = encodeURI(title);
                    const typeParsed = type === "MOVIE" ? "films" : "series";
                    return `${url}?search=${titleEncoded}&p=${typeParsed}`;
                }
            },
            {
                name: 'Extreme download',
                icon: 'https://www.extreme-down.moe/favicon.ico',
                buildUrl: ({ title, type }) => {
                    const url = 'https://www.extreme-down.moe/';
                    const titleEncoded = encodeURI(title);
                    const typeParsed = type === "movie" ? "films" : "series";
                    return `${url}?search=${titleEncoded}&p=${typeParsed}`;
                }
            },
            {
                name: 'Wawacity',
                icon: 'https://www.wawacity.homes/favicon.ico',
                buildUrl: ({ title, type }) => {
                    const url = 'https://www.wawacity.homes/';
                    const titleEncoded = encodeURI(title);
                    const typeParsed = type === "MOVIE" ? "films" : "series";
                    return `${url}?search=${titleEncoded}&p=${typeParsed}`;
                }
            },
            {
                name: 'Darkino',
                icon: 'https://www.darkino.be/static/darkino/img/favicon-32x32.png',
                buildUrl: ({ title, type }) => {
                    const url = 'https://www.darkino.be/';
                    const titleEncoded = encodeURI(title);
                    const typeParsed = type === "MOVIE" ? "movie" : "tv";
                    return `${url}/search?query=${titleEncoded}&type=${typeParsed}`;
                }
            }
        ],
        links: [],
        chosenSite: null,
        videoLink: null,
        videoTitle: null,
    }),
    methods: {
        setUptoboxLink() {
            this.$emit('changeVideos', this.links);
        },

        open(site) {
            const url = site.buildUrl({
                title: this.playlistProp.titleVO,
                imdbType: this.playlistProp.type
            })
            window.open(url, '_blank');
        },

        addLink() {
            this.links.push({ title: this.videoTitle, uptoboxLink: this.videoLink });
        },

        deleteLink(link) {
            this.links = this.links.filter(l => l.uptoboxLink !== link.uptoboxLink);
        },

        load() {
            // console.log(JSON.stringify(data));
            // console.log(this.$refs.frame);
            // console.log(document.getElementById('iframe').document.body.innerHTML)
            // console.log(document.getElementById('myIFrame').document.location.href);
            // console.log(document.getElementById("iframe").contentWindow.location.href);
        }
    },
}


</script>

<style scoped lang="scss">
.uptobox-form {

}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

//.parent {
//    display: grid;
//    grid-template-columns: 40% auto 40%;
//}
//
//.site-list {
//    display: inline-grid;
//    grid-template-columns: auto auto auto;
//    align-items: center;
//    width: 100%;
//    grid-gap: 50px;
//    margin: 50px;
//}

.child {
    width: fit-content;
}

.iframe {
    height: calc(100vh - 100px);
    width: 100%;
}
</style>
