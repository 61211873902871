<template>
    <v-hover v-slot="{ hover }">
        <v-card outlined :shaped="true" width="200" height="440" class="ma-2">

            <v-img :src="image" :aspect-ratio="9/16">
                <v-fade-transition>
                    <v-overlay v-if="hover" absolute>

                        <!-- Redirect to player if there is only one video in the playlist -->
                        <v-btn fab x-large color="purple" @click="setImdbElement">
                            <v-icon size="40">mdi-movie-check</v-icon>
                        </v-btn>
                    </v-overlay>
                </v-fade-transition>
            </v-img>

            <v-card-title>{{ title }}</v-card-title>
            <v-card-subtitle>{{ year }}</v-card-subtitle>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "ImdbElementCard",
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    computed: {
        image() {
            return this.element && this.element.image && this.element.image.url
                ? this.element.image.url
                : require(`@/assets/images/image-not-found.png`);
        },
        title() {
            return this.element && this.element.title ? this.element.title : "";
        },
        year() {
            return this.element && this.element.year ? this.element.year : "";
        }
    },
    methods: {
        setImdbElement() {
            console.log('ImdbElementCard emit');
            this.$emit('setImdbElement', this.element);
        }
    }
}
</script>

<style scoped>

</style>