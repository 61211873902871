<template lang="html">

    <section class="imdb-form">
        <div class="imdb-input">
            <h1>What is the title</h1>
            <v-text-field v-model="title" @keyup.enter="imdbSearch" style="width: 200px;" append-icon="mdi-link-variant" label="Title" single-line hide-details></v-text-field>
            <v-btn @click="imdbSearch" depressed color="primary" style="margin-top: 20px;">Search</v-btn>
        </div>

        <v-container fluid fill-height justify-center>

            <v-progress-circular v-if="searching" indeterminate color="primary" ></v-progress-circular>

            <div v-if="searchedElements !== null">
                <v-container fluid justify-center v-if="searchedElements && searchedElements.length > 0">
                    <v-data-iterator class="iterator" :items="searchedElements" :items-per-page="-1" hide-default-footer disable-filtering disable-pagination>
                        <template v-slot:default="props">
                            <v-container fluid fill-height justify-center>
                                <div v-for="element in props.items" :key="element.id">
                                    <imdb-element-card :element="element" :key="element.id" @setImdbElement="setImdbElement"/>
                                </div>
                            </v-container>
                        </template>
                    </v-data-iterator>
                </v-container>
                <div v-else-if="searchedElements !== null">
                    <h3>No data found for title {{ title }}</h3>
                </div>
            </div>
        </v-container>

    </section>

</template>

<script lang="js">
import ImdbElementCard from "@/components/AddForm/ImdbElementCard.component.vue";
import Services from '@/services/api';

export default {
    name: 'ImdbForm',
    components: {
        ImdbElementCard
    },
    mounted() {

    },
    data: () => ({
        title: null,
        searchedElements: null,
        searching: false
    }),
    methods: {
        async imdbSearch() {
            console.log('searching for :' + this.title);
            if(this.title) {
                this.searching = true;
                const resp = await Services.Imdb.listForTitle(this.title);
                this.searching = false;
                this.searchedElements = resp;
            }
        },
        setImdbElement(element) {
            console.log('element :', element);
            const playlist = {
                titleVO: element.title,
                image: element.image.url,
                type: element.titleType === "movie" ? "MOVIE" : "SERIES",
                imdbId: element.id.split("/")[2]
            }
            this.$emit('changePlaylist', playlist);
        }
    },
    computed: {}
}

</script>

<style scoped lang="scss">
.imdb-form {

    .imdb-input {
        display: grid;
        place-items: center;
        width: 100%;
    }
}

.iterator {
    text-align: center;
}
</style>
