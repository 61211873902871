<template>
    <div>
        <alert ref="alertComponent"></alert>
      <v-stepper v-model="step" vertical>
          <div v-for="(item, index) in stages" :key="index">
              <v-stepper-step :complete="step > (index + 1)" :step="(index + 1)" color="#805acb">
                  {{ item.title }}
                  <small>{{ item.subTitle }}</small>
              </v-stepper-step>
              <v-stepper-content :step="(index + 1)">
                  <v-card>
                      <component :is="item.type" :playlistProp="playlist" @changePlaylist="changePlaylist" @changeVideos="changeVideos"></component>
                  </v-card>
                  <div style="margin-top: 20px;">
<!--                      <v-btn color="#805acb" @click="validate">{{ validateText }}</v-btn>-->
                      <v-btn text @click="step--">Back</v-btn>
                  </div>
              </v-stepper-content>
          </div>
      </v-stepper>
    </div>
</template>

<script>
import VideoForm from "@/components/VideoForm.component"
import ImdbForm from "@/components/AddForm/ImdbForm.component";
import UptoboxForm from "@/components/AddForm/UptoboxForm.component";
import VideoTypeForm from "@/components/AddForm/VideoTypeForm.component";
import Services from "@/services/api";
import Alert from "../../components/Alert.component.vue";

export default {
    name: "EndToEndForm",
    components: {
        Alert,
        ImdbForm,
        UptoboxForm,
        VideoTypeForm,
        VideoForm,
    },
    computed: {
        isLastStage() {
            return this.step === this.stages.length + 1;
        },
        validateText() {
            return this.isLastStage ? "Confirm" : "Next";
        }
    },
    data: () => ({
        step: 1,
        stages: [
            {
                title: "Video data",
                subTitle: "Retrieve data from Imdb",
                type: "ImdbForm"
            },
            {
                title: "Video file",
                subTitle: "Use local or Uptobox files",
                type: "UptoboxForm"
            },
            // {
            //     title: "Summary",
            //     subTitle: "Validate retrieved data",
            //     type: "VideoForm"
            // },
        ],
        videos: [],
        playlist: {}
    }),
    methods: {
        changeVideos(videos) {
            this.videos = [...videos];
            console.log('changeVideo :', this.videos);
            this.validate();
        },
        changePlaylist(playlist) {
            this.playlist = {...playlist};
            console.log('changePlaylist :', this.playlist);
            this.validate();
        },

        validate() {
            console.log(`stages.length=${this.stages.length}, step=${this.step}`);
            this.step++;
            console.log(`stages.length=${this.stages.length}, step updated=${this.step}`);
            console.log('playlist :', this.playlist);
            console.log('videos :', this.videos);

            // if not last stage, just go to the next step
            if(!this.isLastStage) {
                return;
            }

            this.playlist.videos = this.videos.map(l => ({title: l.title, uptoboxLink: l.uptoboxLink}))
            console.log("adding playlist : ", this.playlist);

            Services.Playlists.addPlaylist(this.playlist)
                .then(p => this.$refs.alertComponent.showSuccessMessage("New playlist created with id " + p.id))
                .catch(e => {
                    console.error(e);
                    this.$refs.alertComponent.showErrorMessage(e.message)
                })
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
