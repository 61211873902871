<template>
    <v-form ref="form" v-model="isValid" :lazy-validation="false">
        <v-container>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field v-model="video.title" :counter="255" label="Title" color="#805acb"></v-text-field>
                </v-col>

                <v-col v-if="isMaintainer" cols="12" md="6">
                    <v-text-field v-model="video.uptoboxLink" :counter="20" label="Uptobox Id" color="#805acb"
                                  @paste="onUptoboxLinkPaste"></v-text-field>
                </v-col>
                <v-col v-if="isAdmin && isUpdate" cols="12" md="6">
                    <v-select v-model="video.state" color="#805acb"
                              :items="['TO_DOWNLOAD', 'DOWNLOADING', 'TO_ENCODE', 'ENCODING', 'FINISHED']"></v-select>
                </v-col>

                <v-col v-if="isAdmin && isUpdate" cols="12" md="12">
                    <v-text-field v-model="video.downloadPath" label="Downloaded Path" color="#805acb"></v-text-field>
                </v-col>
                <v-col v-if="isAdmin && isUpdate" cols="12" md="12">
                    <v-text-field v-model="video.path" label="Encoded path" color="#805acb"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-autocomplete v-model="playlist" :items="playlists" item-text="titleVO"
                                    label="Associated playlist" clearable return-object color="#805acb"></v-autocomplete>
                </v-col>

                <v-btn color="success" class="mr-4" @click="submit">
                    {{ buttonText }}
                </v-btn>
                <v-btn color="warning" class="mr-4" @click="resetDefault">
                    Reset Form
                </v-btn>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "VideoForm",
    props: {
        isUpdate: {
            required: false,
            type: Boolean,
            default: false
        },
        videoProp: {
            required: false,
            type: Object,
            default: () => ({title: "some title"}),
        }
    },
    mounted() {
        console.log("videoProp :", this.videoProp);
        this.video = { ...this.videoProp };
    },
    data: () => ({
        isValid: true,
        oldVideo: {},
        video: {},
        playlist: {},
        playlists: []
    }),
    computed: {
        ...mapGetters(["user"]),
        buttonText: function () {
            return this.isUpdate ? 'Update' : 'Add';
        },
        isAdmin() {
            if (!this.user) return false;
            return this.user.isAdmin;
        },
        isMaintainer: function () {
            if (!this.user) return false;
            return this.user.isModerator;
        },
        /**
         * return the updated fields
         */
        getUpdatedData: function () {
            return {
                id: this.oldVideo.id,
                title: (this.video.title !== this.oldVideo.title) ? this.video.title : null,
                uptoboxLink: (this.video.uptoboxLink !== this.oldVideo.uptoboxLink) ? this.video.uptoboxLink : null,
                state: (this.video.state !== this.oldVideo.state) ? this.video.state : null,
                downloadPath: (this.video.downloadPath !== this.oldVideo.downloadPath) ? this.video.downloadPath : null,
                path: (this.video.path !== this.oldVideo.path) ? this.video.path : null,
                playlist: (this.playlist?.id !== this.oldVideo?.playlist?.id) ? this.playlist : null
            }
        },
    },
    methods: {
        onUptoboxLinkPaste(evt) {
            evt.preventDefault();
            const link = evt.clipboardData.getData('text/plain');
            if (link.includes('/')) {
                const splitted = link.split('/');
                this.video.uptoboxLink = splitted[splitted.length - 1];
            } else {
                this.video.uptoboxLink = link;
            }
        },
        setVideo(video) {
            this.oldVideo = {...video};
            this.video = video;
            this.playlist = video?.playlist;
            this.resetDefault();
        },
        setPlaylists(playlists) {
            this.playlists = playlists;
        },
        /**
         * send submit event to parent
         */
        submit() {
            if (!this.$refs.form.validate()) return;
            this.$emit('submit', this.getUpdatedData);
        },
        /**
         * reset form with default video values
         */
        resetDefault() {
            this.video = {...this.oldVideo};
        },
    }
}
</script>

<style scoped>

</style>